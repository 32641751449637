@import '../../assets/scss/abstract/abstract';

.container {
  max-width: var(--yps-max-width);
  margin: 100px auto;
  text-align: center;
}

.title {
  font-weight: 300;
  margin: 0;
}

.subTitle {
  margin: 0;
}

.columns {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 50px;

  > :first-child {
    align-self: center;
    justify-self: flex-end;
  }

  > :last-child {
    align-self: center;
    justify-self: flex-start;
    text-align: left;
  }
}

.message {
  margin: 30px 0;
}

.countdownWrapper {
  font-size: 32px;
  font-weight: 300;

  > * {
    margin: 30px auto;
  }

}